import EnumPeriodForAnalytic from '../../../../common/models/enums/EnumPeriodForAnalytic';

export default class EndpointsTimeStatisticAllRequest  {
	ProjectLogGroupId?: number;
	FromDate: Date;
	ToDate: Date;
	Period: EnumPeriodForAnalytic;
	constructor(obj?: Partial<EndpointsTimeStatisticAllRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
