
import { Options, Vue, Watch } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import ChartStatistic from "@/views/components/chart-statistic.vue";
import FilterModel from "@/views/page/components/filter-model";
import ChartDataModel from "@/views/components/chartDataModel";
import ChartDatsetsModel from "@/views/components/chartDatasetsModel";
import getUTC from "@/function/getUTC"
import EndpointsTimeStatisticAllResponse from "@/dataSource/api/analytic-endpoints/i-services/models/response/EndpointsTimeStatisticAllResponse";
import EndpointsTimeStatisticAllRequest from "@/dataSource/api/analytic-endpoints/i-services/models/request/EndpointsTimeStatisticAllRequest";

@Options({
  name: "AverageResponseTimeOfMethods",
  components: {
    Page,
    ChartStatistic,
  },
})
export default class AverageResponseTimeOfMethods extends Vue {
  filter: FilterModel = new FilterModel();
  endpointsResponse: Array<EndpointsTimeStatisticAllResponse> = [];
  endpointsRequest: EndpointsTimeStatisticAllRequest =
    new EndpointsTimeStatisticAllRequest();
  flag = false;
  isLoading = false;

  isLiveReload = false;
  timerId = 0;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  datasets1 = new ChartDatsetsModel({
    label: "Колличество запросов",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
    pointRadius: 5,
  });
  datasets2 = new ChartDatsetsModel({
    label: "Среднее время отклика (м. сек)",
    data: [],
    borderColor: "#0000FF",
    backgroundColor: "#0000FF",
    yAxisID: "y1",
    pointStyle: "circle",
    pointRadius: 5,
  });

  chartModel = new ChartDataModel({
    labels: [],
    datasets: [this.datasets1, this.datasets2],
  });


  @Watch("filter", { deep: true })
  update( newFilter, oldFilter) {
      this.getData();
  }

  unmounted() {
    clearInterval(this.timerId)
  }

  liveReload(value) {
    this.isLiveReload = value
    if (value) {
      this.timerId = setInterval(() => {
        this.filter.DateTo = new FilterModel().DateTo
      }, 5000)
    } else {
      clearInterval(this.timerId)
    }
  }

  formatDate(stringDate: string | any): string {
    let index = stringDate.indexOf(" (");
    if (~index) {
      return stringDate.substr(0, index);
    }
  }

  async getData() {
    this.isLoading = true
    let res: EndpointsTimeStatisticAllResponse[];
    this.endpointsRequest = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Period: this.filter.interval,
    };
    let tmpFilter = getUTC(this.endpointsRequest)
    try {
      res =
        await this.$api.AnalyticEndpointsService.getAllEndpointsTimeStatisticAsync(
          tmpFilter
        );
      this.chartModel.labels.splice(0, this.chartModel.labels.length);
      this.datasets1.data.splice(0, this.datasets1.data.length);
      this.datasets2.data.splice(0, this.datasets2.data.length);
      res.forEach((elem) => {
        this.chartModel.labels.push(elem.TimePoint.toString());
        this.datasets1.data.push(elem.Count);
        this.datasets2.data.push(Number(elem.AverageResponseTime.toFixed(2)));
      });
      this.flag = true;
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false
  }
}
